import React, { Component } from "react";
import axios from "axios";
import EditForm from "./InventoryItemForm";
import { Layout, Icon, Table, Button, Form, Input } from "antd";
import { Link, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import config from "../config";
import * as itemService from "../services/itemService";
import _ from "lodash";

const { Header, Sider, Content } = Layout;

const _FetchCountEachTime = 200;

export default class List extends Component {
  state = {
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total, range) => {
        return `Showing ${range[0]}-${range[1]} of ${total} Results`;
      },
      pageSizeOptions: ["1", "10", "20", "30", "40"]
    },
    loading: false
  };

  columns = [
    {
      title: "SKU",
      dataIndex: "sku",
      rowKey: "sku",
      width: "22%"
    },
    {
      title: "Condition",
      dataIndex: "condition",
      rowKey: "condition",
      width: "22%",
      render: text => text
    },
    {
      title: "Actions",
      dataIndex: "Edit",
      rowKey: "Edit",
      width: "10%",
      render: (text, row) => {
        let to = { pathname: `edit/${row.sku}`, item: row };

        return (
          <div className="isoInvoiceBtnView">
            <Link to={to}>
              <Button color="primary" className="invoiceViewBtn">
                Edit
              </Button>
            </Link>
          </div>
        );
      }
    }
  ];

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: pagination
    });
    this.fetch({
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize
      // sortField: sorter.field,
      // sortOrder: sorter.order,
      // ...filters
    });
  };

  async componentDidMount() {
    this.fetch();
  }

  fetch = async (
    params = {
      limit: this.state.pagination.pageSize,
      offset: (this.state.pagination.current - 1) * this.state.pagination.pageSize
      // sortField: sorter.field,
      // sortOrder: sorter.order,
      // ...filters
    }
  ) => {
    console.log("params:", params);
    this.setState({ loading: true });
    try {
      const data = await this.fetchList(params);

      // const { data } = await itemService.getItemList(params);
      console.log("TCL: List -> fetchList -> data", data);
      const list = (data && data.body && data.body.inventoryItems) || [];
      this.setState({ dataSource: list });

      console.log("TCL: componentDidMount -> data", data);
      const pagination = { ...this.state.pagination, total: data.body.total || 0 };

      this.setState({
        loading: false,
        data: data,
        pagination
      });

      let total = data.body.total;
      let currentSize = data.body.size;

      if (total > currentSize) {
        const moreResult = await this.fetchList({
          limit: _FetchCountEachTime,
          offset: currentSize
        });

        total = total + ((moreResult && moreResult.body.total) || 0);
        const moreList = (moreResult && moreResult.body && moreResult.body.inventoryItems) || [];
        console.log("TCL: List -> moreList", moreList);
        this.state.dataSource = [...this.state.dataSource, ...moreList];
        console.log("TCL: List -> this.state.dataSource", this.state.dataSource);
        this.setState({ dataSource: this.state.dataSource });
      }
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  async fetchList(param = { limit: "200", offset: "0" }) {
    const { data } = await itemService.getItemList(param);
    console.log("TCL: List -> fetchList -> data", data);
    // const list = data && data.body && data.body.inventoryItems;
    // const productList = list || [];
    // this.setState({ dataSource: productList });
    return data;
  }

  onFilterItems = () => {
    console.log("TCL: onFilterItems -> onFilterItems");

    this.setState({ searchSKU: this.searchSKU });
  };

  render() {
    if (!localStorage.passcode) {
      return <Redirect to={"/login"} />;
    }

    let itemList = this.state.dataSource || [];
    if (this.state.searchSKU) {
      itemList = itemList.filter(i => i.sku && i.sku.toLowerCase().indexOf(this.state.searchSKU.toLowerCase()) >= 0);
    }

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <Form layout="inline">
            <Form.Item>
              <Input
                placeholder="SKU"
                onChange={e => {
                  this.searchSKU = e.target.value;
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="button" onClick={this.onFilterItems}>
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div>
          <Table
            rowKey="sku"
            dataSource={itemList || []}
            columns={this.columns}
            pagination={true}
            // onChange={this.handleTableChange}
            loading={this.state.loading}
            showSizeChanger={true}
            className="invoiceListTable"
          />
        </div>
      </div>
    );
  }
}
