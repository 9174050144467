import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./container/Login";
import List from "./container/List";
import SpeakerForm from "./container/InventoryItemForm";
import { Layout, Icon, Table } from "antd";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";

const { Header, Sider, Content } = Layout;

function App() {
  return (
    <div className="App">
      <Layout>
        <Header style={{ background: "#fff", padding: 0, textAlign: "center" }}>
          <h3>Ebay Listing Tool </h3>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 40,
            background: "#fff",
            minHeight: 280
          }}
        >
          <Router>
            <div className="main-content">
              <Route  path="/login" component={Login} />
              <Route exact path="/" component={List} />
              <Route path="/edit/:sku" component={SpeakerForm} />
              {/* <Route path="/headphone-edit/:sku" component={HeadphoneForm} /> */}
            </div>
          </Router>
        </Content>
      </Layout>
    </div>
  );
}

export default App;
