import React, { Component } from "react";
import axios from "axios";
import EditForm from "./InventoryItemForm";
import { Layout, Icon, Table, Button, Form, Input, notification } from "antd";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import config from "../config";
import * as itemService from "../services/itemService";
import _ from "lodash";

const { Header, Sider, Content } = Layout;

export default class List extends Component {
  state = {
    loading: false,
    passcode: null
  };

  async componentDidMount() {}

  login =  () => {
    const { passcode } = this.state;
    console.log("TCL: List -> login -> passcode", passcode);
    if (!passcode) {
      notification.error({ message: "error", description: "token required." });
      return;
    }

    try {
      // const { data } = await Axios.post(config.apiUrl + "/login", { passcode: passcode });
      localStorage.passcode = passcode;
      this.setState({ logged: true });
      this.props.history.push("/");
    } catch (error) {
      notification.error({ message: "error", description: "login failed, please check passcode." });
    }
  };

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Form layout="inline">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Auth Token"
              value={this.state.passcode}
              onChange={e => {
                this.setState({ passcode: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.login}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
