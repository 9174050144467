import React, { Component, PropTypes } from "react";
import { Form, Button, Input, Icon, InputNumber, Radio, Checkbox, Select, DatePicker, TimePicker, AutoComplete } from "antd";
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

class FreeTextSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      dataSource: []
    };
  }
  componentDidMount() {}

  onSearch = value => {
    this.setState({ searchText: value });
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  };

  render() {
    const { dataSource } = this.props;
    const filteredDataSource = (dataSource || []).filter((item)=> {
			// console.log("TCL: FreeTextSelect -> filteredDataSource -> item", item)
      return (
        (item.value && item.value.toUpperCase().indexOf(this.state.searchText.toUpperCase()) >= 0) ||
        (item.text && item.text.toUpperCase().indexOf(this.state.searchText.toUpperCase()) >= 0)
      );
    });

    return (
      <AutoComplete
        {...this.props}
        dataSource={filteredDataSource || []}
        // style={{ width: 200 }}
        onSelect={this.onSelect}
        onSearch={this.onSearch}

        // filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}

        // placeholder="input here"
      >
        <Input suffix={<Icon type="down" style={{fontSize:10}}  className="certain-category-icon" />} />
      </AutoComplete>
    );
  }
}

export default FreeTextSelect;
