import React, { Component } from "react";
import {
  Form,
  Button,
  Modal,
  Radio,
  Select,
  Row,
  Col,
  Input,
  Checkbox,
  notification,
  Tabs,
  Table,
  InputNumber,
  Spin,
  Icon
} from "antd";
import axios from "axios";
import config from "../config";
import _ from "lodash";

import FreeTextSelect from "../components/freeTextSelect";
import * as Options from "../options";
import * as itemService from "../services/itemService";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { TextArea } = Input;

const TabPane = Tabs.TabPane;

class EditForm extends Component {
  constructor(props) {
    super(props);
    // const { item } = this.props.location;
    // if (item.product.aspects) {
    //   item.product.aspects = JSON.stringify(item.product.aspects || {});
    // }
    this.state = {
      inventoryItem: null,
      offerList: [],
      offerModalVisible: false,
      currentOffer: null,
      editOfferTmp: null,
      isGroup: false
    };
  }

  columns = [
    {
      title: "Actions",
      dataIndex: "Edit",
      rowKey: "Edit",
      width: "10%",
      render: (text, row) => {
        return (
          <div className="isoInvoiceBtnView">
            <Button
              color="primary"
              className="invoiceViewBtn"
              onClick={() => {
                this.editOrder(row);
              }}
            >
              Edit
            </Button>
          </div>
        );
      }
    },
    {
      title: "SKU",
      dataIndex: "sku",
      rowKey: "sku",
      width: "22%",
      render: (text, row) => {
        return (
          <a target="_blank" href={`https://www.ebay.com/itm/${row.listing && row.listing.listingId}`}>
            {text}
          </a>
        );
      }
    },
    {
      title: "marketplaceId",
      dataIndex: "marketplaceId",
      rowKey: "marketplaceId",
      width: "22%",
      render: text => text
    },
    {
      title: "price",
      dataIndex: "pricingSummary.price.value",

      width: "22%",
      render: text => text
    },
    {
      title: "currency",
      dataIndex: "pricingSummary.price.currency",
      width: "22%",
      render: text => text
    },
    {
      title: "quantity",
      dataIndex: "availableQuantity",
      width: "22%",
      render: text => text
    }
  ];

  async componentDidMount() {
    console.log("TCL: EditForm -> componentDidMount -> this.props", this.props);
    const { sku } = this.props.match.params;

    // const { item } = this.props.location;

    // document.getElementById("iframe").contentWindow.document.body.innerHTML = item.product.description;

    try {
      const itemResult = await itemService.getItem(sku);
      const item = itemResult && itemResult.data.body;

      if (item.product.aspects) {
        item.product.aspects = JSON.stringify(item.product.aspects || {});
      }

      this.setState({ inventoryItem: item });

      if (item.groupIds && item.groupIds.length > 0) {
        //如果是group

        const groupId = item.groupIds[0];
        this.setState({ groupId });

        const groupResult = await itemService.getItemGroup(groupId);
        if (groupResult && groupResult.data.body) {
          if (groupResult && groupResult.data.body.aspects) {
            groupResult.data.body.aspects = JSON.stringify(groupResult.data.body.aspects || {});
          }
          console.log("TCL: EditForm -> componentDidMount -> groupResult", groupResult);
          this.setState({ isGroup: true, inventoryItem: groupResult && groupResult.data.body }, () => {
            document.getElementById("iframe").contentWindow.document.body.innerHTML = groupResult.data.body.description;
          });
        }
      }

      // TODO: 搞完之后用这个：
      const { data } = await itemService.getOfferList(sku);

      // const { data } = await axios.get(config.apiUrl + "offers", {});
      console.log("TCL: EditForm -> componentDidMount -> offer list", data);

      const offers = data.body.offers || [];
      this.setState({ offerList: offers });
    } catch (error) {
      console.log("TCL: EditForm -> componentDidMount -> error", error);
    }
  }

  getPureDescription = description => {
    const iframeDoc = window.$(document.getElementById("iframeOffer").contentWindow.document);
    iframeDoc.find("script").remove();
    const pureDescription = iframeDoc[0].body.innerHTML;
    console.log("TCL: removeUnusedHtmlFromDescription -> pureDescription", pureDescription);
    description = _.trim(pureDescription);
    this.state.editOfferTmp.listingDescription = description;
    this.setState({ editOfferTmp: this.state.editOfferTmp });
  };

  removeUnusedHtmlFromDescription = () => {
    const iframeDoc = window.$(document.getElementById("iframe").contentWindow.document);
    iframeDoc.find("script").remove();
    const pureDescription = iframeDoc[0].body.innerHTML;
    console.log("TCL: removeUnusedHtmlFromDescription -> pureDescription", pureDescription);
    this.state.inventoryItem.description = _.trim(pureDescription);
    this.setState({ inventoryItem: this.state.inventoryItem });
  };

  saveInventoryItem = async () => {
    const { inventoryItem } = this.state;

    if (this.state.isGroup) {
      try {
        try {
          if (inventoryItem.aspects) {
            inventoryItem.aspects = JSON.parse(inventoryItem.aspects);
          }
        } catch (error) {
          notification.error({ message: "save error", description: "invalid aspects format" });
          return;
        }

        const { data } = await itemService.saveItemGroup(this.state.groupId, inventoryItem);
        console.log("TCL: EditForm -> saveInventoryItem -> data", data);
        if (data.body.errors && data.body.errors.length > 0) {
          notification.error({ message: "save error", description: data.body.errors[0].message || "" });
          return;
        }

        notification.success({ message: "save successfully." });
        this.props.history.push("/");
      } catch (error) {
        console.log("TCL: EditForm -> save -> error", error);
        notification.error({ message: "save error", description: (error && error.message) || "" });
      }
    } else {
      try {
        if (inventoryItem.product.aspects) {
          inventoryItem.product.aspects = JSON.parse(inventoryItem.product.aspects);
        }
      } catch (error) {
        notification.error({ message: "save error", description: "invalid aspects format" });
        return;
      }

      try {
        const { data } = await itemService.saveItem(inventoryItem);
        console.log("TCL: EditForm -> saveInventoryItem -> data", data);
        if (data.body.errors && data.body.errors.length > 0) {
          notification.error({ message: "save error", description: data.body.errors[0].message || "" });
          return;
        }

        notification.success({ message: "save successfully." });
        this.props.history.push("/");
      } catch (error) {
        console.log("TCL: EditForm -> save -> error", error);
        notification.error({ message: "save error", description: (error && error.message) || "" });
      }
    }
  };

  editOrder = async row => {
    const { data } = await itemService.getOffer(row.offerId);

    console.log("TCL: EditForm -> editOrder -> data", data);
    this.setState({
      offerModalVisible: true,
      currentOffer: data.body,
      editOfferTmp: JSON.parse(JSON.stringify(data.body))
    });
    document.getElementById("iframeOffer").contentWindow.document.body.innerHTML=data.body&&data.body.listingDescription||'';
  };

  saveOffer = async () => {
    let { editOfferTmp, currentOffer } = this.state;

    this.setState({ saveOfferLoading: true });
    try {
      const { data } = await itemService.saveOffer(editOfferTmp);

      if (data.body.errors && data.body.errors.length > 0) {
        notification.error({ message: "save error", description: data.body.errors[0].message || "" });
        return;
      }

      currentOffer = { ...currentOffer, ...editOfferTmp };

      let existedOffer = this.state.offerList.find(offer => offer.offerId == currentOffer.offerId);
      console.log("TCL: EditForm -> saveOffer -> existedOffer", existedOffer);
      if (existedOffer) {
        existedOffer = Object.assign(existedOffer, currentOffer);
      }
      this.setState({ offerModalVisible: false, saveOfferLoading: false });
      notification.success({ message: "save successfully." });
      // this.props.history.push("/");
    } catch (error) {
      this.setState({ saveOfferLoading: false });
      console.log("TCL: EditForm -> save -> error", error);
      const { response } = error;
      console.log("TCL: EditForm -> saveOffer -> response", response);
      notification.error({ message: "save error", description: (error && error.message) || "" });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 24,
          offset: 0
        }
      }
    };

    const { inventoryItem, editOfferTmp } = this.state;

    if (!inventoryItem) {
      return (
        <div>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    // console.log("TCL: EditForm -> render -> inventoryItem", inventoryItem, inventoryItem.product.aspects);
    return (
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <h3 style={{ background: "#fff", padding: 0 }}>{inventoryItem.sku}</h3>
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab="inventoryItem" key="1">
            <Form>
              {/* <Row>
                <Col md={24} sm={24}>
                  <Form.Item label={"aspects"}>
                    <TextArea
                      rows={5}
                      value={inventoryItem.product.aspects}
                      onChange={e => {
                        inventoryItem.product.aspects = e.target.value;
                        this.setState({ inventoryItem: inventoryItem });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              {this.state.isGroup ? (
                <div>
                  <div>Group:</div>
                  <Row>
                    <Col md={24} sm={24}>
                      <Form.Item label={"aspects"}>
                        <TextArea
                          rows={5}
                          value={inventoryItem.aspects}
                          onChange={e => {
                            inventoryItem.aspects = e.target.value;
                            this.setState({ inventoryItem: inventoryItem });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} sm={24}>
                      <Form.Item label={"description"}>
                        <Button type="ghost" onClick={this.removeUnusedHtmlFromDescription}>
                          remove scripts and others
                        </Button>
                        <TextArea
                          rows={15}
                          value={inventoryItem.description}
                          onChange={e => {
                            inventoryItem.description = e.target.value;
                            this.setState({ inventoryItem: inventoryItem });

                            document.getElementById("iframe").contentWindow.document.body.innerHTML =
                              inventoryItem.description;
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={24} sm={24} offset={0}>
                      <Form.Item label={"description"}>
                        <iframe
                          id="iframe"
                          title="description"
                          frameBorder="0"
                          style={{ minWidth: 500, width: "100%", margin: "10px 0 auto", minHeight: 400 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Row>
                  <Col md={24} sm={24}>
                    <Form.Item label={"aspects"}>
                      <TextArea
                        rows={5}
                        value={inventoryItem.product.aspects}
                        onChange={e => {
                          inventoryItem.product.aspects = e.target.value;
                          this.setState({ inventoryItem: inventoryItem });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="button" onClick={this.saveInventoryItem}>
                  Save
                </Button>
                <Button
                  type="ghost"
                  htmlType="button"
                  style={{ marginLeft: 50 }}
                  onClick={() => this.props.history.goBack()}
                >
                  cancel
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Offer" key="2">
            <div>
              <Table
                rowKey="offerId"
                dataSource={this.state.offerList || []}
                columns={this.columns}
                pagination={true}
                //   onChange={this.handleTableChange}
                loading={this.state.loading}
                showSizeChanger={true}
                className="invoiceListTable"
              />
            </div>
          </TabPane>
        </Tabs>

        <Modal
          title="Edit Offer"
          centered
          visible={this.state.offerModalVisible}
          onOk={() => this.saveOffer()}
          onCancel={() => this.setState({ offerModalVisible: false })}
          okButtonProps={{ loading: this.state.saveOfferLoading }}
        >
          <Form>
            <Row>
              <Col md={24} sm={24}>
                <Form.Item label={"quantity"}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={editOfferTmp && editOfferTmp.availableQuantity}
                    onChange={value => {
                      editOfferTmp.availableQuantity = value;
                      this.setState({ editOfferTmp: editOfferTmp });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={24} sm={24}>
                <Form.Item label={"currency"}>
                  <Input
                    value={editOfferTmp && editOfferTmp.pricingSummary&& editOfferTmp.pricingSummary.price&& editOfferTmp.pricingSummary.price.currency}
                    onChange={e => {
                      editOfferTmp.pricingSummary.price.currency = e.target.value;
                      this.setState({ editOfferTmp: editOfferTmp });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={24} sm={24}>
                <Form.Item label={"currency"}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={editOfferTmp && editOfferTmp.pricingSummary&& editOfferTmp.pricingSummary.price&& editOfferTmp.pricingSummary.price.value}
                    onChange={value => {
                      editOfferTmp.pricingSummary.price.value = value;
                      this.setState({ editOfferTmp: editOfferTmp });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={24} sm={24}>
                <Button type="ghost" onClick={this.getPureDescription}>
                  remove scripts and others
                </Button>
                <Form.Item label={"description"}>
                  <TextArea
                    rows={7}
                    value={editOfferTmp && editOfferTmp.listingDescription}
                    onChange={e => {
                      editOfferTmp.listingDescription = e.target.value;
                      document.getElementById("iframeOffer").contentWindow.document.body.innerHTML =
                      editOfferTmp.listingDescription;
                      this.setState({ editOfferTmp: editOfferTmp });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={24} sm={24} offset={0}>
                <Form.Item label={"description"}>
                  <iframe
                    id="iframeOffer"
                    title="description"
                    frameBorder="0"
                    style={{ minWidth: 500, width: "100%", margin: "10px 0 auto", minHeight: 400 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const EditFormCreate = Form.create()(EditForm);
export default EditFormCreate;
