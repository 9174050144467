import axios from "axios";

import config from "../config";

function getAuthToken() {
  return localStorage.passcode || "uv9EykJpXr2sH9cK";
}

export function getItemList(queryParam) {
  const params = {
    method: "get",
    path: "/sell/inventory/v1/inventory_item",
    query: [["limit", "200"], ["offset", (queryParam.offset || "0").toString()]]
    //(queryParam.limit || "200").toString()
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export function getItem(sku) {
  const params = {
    method: "get",
    path: "/sell/inventory/v1/inventory_item/" + sku
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export function saveItem(inventoryItem) {
  const params = {
    method: "put",
    path: `/sell/inventory/v1/inventory_item/${inventoryItem.sku}`,
    headers: [["Content-Language", "en-US"]],
    body: inventoryItem
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}


export function getItemGroup(inventoryItemGroupKey) {
  const params = {
    method: "get",
    path: "/sell/inventory/v1/inventory_item_group/" + inventoryItemGroupKey
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export function saveItemGroup(inventoryItemGroupKey, itemGroup) {
  const params = {
    method: "put",
    path: `/sell/inventory/v1/inventory_item_group/${inventoryItemGroupKey}`,
    headers: [["Content-Language", "en-US"]],
    body: itemGroup
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}


export function getOfferList(sku) {
  const params = {
    method: "get",
    path: "/sell/inventory/v1/offer",
    query: [["sku", sku]]
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export function getOffer(id) {
  const params = {
    method: "get",
    path: "/sell/inventory/v1/offer/" + id
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export function saveOffer(offer) {
  const params = {
    method: "put",
    path: "/sell/inventory/v1/offer/" + offer.offerId,
    headers: [["Content-Language", "en-US"]],
    body: offer
  };

  return axios.post(config.apiUrl, params, {
    headers: {
      Authorization: getAuthToken(),
      "Content-Type": "application/json"
    }
  });
}

export default {};
